<template>
  <div>
    <x-select-periodo-fiscal class="mb-3" @input="onChangePeriodoFiscal" modulo="Reportes por saldos"/>

    <b-overlay :show="loading">
      <template #overlay>
        <loading />
      </template>

      <x-select-momento-contable
        class="mb-3"
        @input="onChangeMomento"
      />

      <b-table
        :fields="fields"
        :items="getSaldos"
        striped
        bordered
        responsive
        hover
        stacked="lg"
        show-empty
      >
        <template #cell()="data">
          <div v-if="getKeyName(data) == 'importe'">
            {{ $formatNumber(data.value) }}
          </div>
          <div v-else>
            {{ data.value }}
          </div>
        </template>
      </b-table>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MomentoContable',

  data () {
    return {
      reset: false,

      loading: false,

      fields: [
        { key: 'clave_unidad_administrativa', label: 'Unidad Administrativa' },
        { key: 'clave_fuente_financiamiento', label: 'Fuente de Financiamiento' },
        { key: 'clave_proyecto', label: 'Proyecto' },
        { key: 'clave_partida', label: 'Partida' },
        { key: 'mes', label: 'Mes' },
        { key: 'importe', label: 'Importe' }
      ],

      periodo_fiscal_id: null
    }
  },

  computed: {
    ...mapGetters('saldoModule', [
      'getSaldos'
    ]),

    ...mapGetters('fiscalPeriodsModule', [
      'getAllFiscalPeriods'
    ])
  },

  methods: {
    async onChangePeriodoFiscal (periodo_fiscal_id) {
      this.loading = true
      const { error, message } = await this.$store.dispatch('saldoModule/getSaldosMomentosContables',
        {
          periodo_fiscal_id: periodo_fiscal_id,
          momento: '8240',
          mes: 1
        })
      this.loading = false

      this.periodo_fiscal_id = periodo_fiscal_id

      if (error) return this.$notify({ error, message }, 'Error')
    },

    async onChangeMomento (partida_id) {
      if (!partida_id && this.periodo_fiscal_id) {
        this.onChangePeriodoFiscal(this.periodo_fiscal_id)
        return
      }

      if (!this.periodo_fiscal_id) return

      this.loading = true

      const { error, message } = await this.$store.dispatch('saldoModule/getSaldosPorPartida', {
        periodo_fiscal_id: this.periodo_fiscal_id,
        partida_id: partida_id
      })

      this.loading = false

      if (error) return this.$notify({ error, message }, 'Error')
    },

    getKeyName (data) {
      return data.field.key
    }
  }
}
</script>
